<template>
    <zw-sidebar @shown="shown" :title="$t('settings.warehouse.title')">
        <validation-observer tag="div" ref="observer">
            <b-overlay :show="loading" no-wrap></b-overlay>
            <div v-if="!loading">
                <b-row>
                    <b-col cols="12">
                        <zw-input-group v-model="form.shelf_name"
                                        name="shelf_name"
                                        :label-prefix="labelPrefix"
                                        validate="required"
                        ></zw-input-group>
                    </b-col>
                </b-row>

                <b-row>
                    <b-col cols="12">
                        <zw-input-group v-model="form.shelf_count"
                                        name="shelf_count"
                                        :label-prefix="labelPrefix"
                                        validate="required"
                                        type="number"
                        ></zw-input-group>
                    </b-col>

                    <b-col cols="12">
                        <zw-input-group v-model="form.capacity"
                                        name="capacity"
                                        :label-prefix="labelPrefix"
                                        validate="required"
                                        type="number"
                        ></zw-input-group>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col sm="12" class="text-sm-right">
                        <b-button block @click="onSubmit()" variant="primary">
                            {{ $t('common.button.save') }}
                        </b-button>
                    </b-col>
                </b-row>
            </div>
        </validation-observer>
    </zw-sidebar>
</template>

<script>
import {mapGetters} from 'vuex'
import commonSave from '@/bundles/erika_common_bundle/mixins/common-save'

export default {
    name: 'GenerateShelvesModal',
    mixins: [commonSave],
    data() {
        return {
            loading: true,
            payload: {},
            callback: null,
            defaultForm: {
                shelf_name: '',
                shelf_count: 1,
                capacity: 1,
            },
            form: {},
            labelPrefix: 'stock.label.',
        }
    },
    methods: {
        shown() {
            this.loading = false
            this.form.rackNum = this.payload.rackNum
            this.form.warehouseId = this.payload.warehouseId
        },
        onSubmit() {
            this.$refs['observer'].validate().then(valid => {
                if (valid) {
                    this.loading = true

                    this.$store.dispatch('Stock/generateShelves', this.form)
                        .then((response) => {
                            this.commonAfterSave(response)
                        })
                        .catch(errors => {
                            console.log(errors)
                            this.$refs['observer'].setErrors(errors)
                            this.showValidationError()
                        })
                        .finally(() => {
                            this.loading = false;
                        })
                } else {
                    this.showValidationError()
                }
            })
        },
    }
}
</script>